import { useEffect, useState } from 'react';
import { getTimeRemaining } from '@/shared/lib/utils/getTimeRemaining';

export const useRemainingTimer = (time: number, timeAt: number) => {
    const [timeRemaining, setTimeRemaining] = useState(
        getTimeRemaining(time, timeAt),
    );

    useEffect(() => {
        const interval = setInterval(
            () => setTimeRemaining(getTimeRemaining(time, timeAt)),
            1000,
        );

        return () => clearInterval(interval);
    }, [timeAt]);

    return timeRemaining;
};
