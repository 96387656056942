import classNames from 'classnames';
import s from './Input.module.scss';
import { ChangeEvent } from 'react';
import CheckIcon from '@/shared/assets/icons/check.svg';
import WarningIcon from '@/shared/assets/icons/warning.svg';
import { PatternFormat } from 'react-number-format';

interface InputProps {
    label?: string;
    className?: string;
    placeholder?: string;
    error: string;
    value: string;
    onChange: (e: ChangeEvent<HTMLInputElement>) => void;
    disabled?: boolean;
    isDirty?: boolean;
    baseAddon?: boolean;
    format?: string;
}

export const Input = (props: InputProps) => {
    const {
        className,
        label,
        placeholder,
        error,
        value = '',
        onChange,
        disabled,
        isDirty,
        baseAddon = true,
        format,
    } = props;

    const mods = {
        [s.disabled]: disabled,
        [s.isError]: !!error,
    };

    return (
        <div className={classNames(s.Input, mods, [className])}>
            {label && (
                <label className={s.label} htmlFor={label}>
                    {label}
                </label>
            )}
            <div className={s.wrapper}>
                {format ? (
                    <PatternFormat
                        className={classNames(
                            s.input,
                            { [s.baseAddon]: baseAddon },
                            [className],
                        )}
                        value={value}
                        onChange={onChange}
                        placeholder={placeholder}
                        format={format}
                        id={label}
                        disabled={disabled}
                        mask={'_'}
                    />
                ) : (
                    <input
                        className={classNames(
                            s.input,
                            { [s.baseAddon]: baseAddon },
                            [className],
                        )}
                        value={value}
                        onChange={onChange}
                        placeholder={placeholder}
                        type={'text'}
                        id={label}
                        disabled={disabled}
                    />
                )}

                {baseAddon && isDirty && (
                    <div className={s.status}>
                        {error ? (
                            <WarningIcon className={s.warning} />
                        ) : (
                            <CheckIcon className={s.check} />
                        )}
                    </div>
                )}
            </div>
            {error && error.trim().length > 0 && (
                <span className={s.error}>{error}</span>
            )}
        </div>
    );
};
