import { object, string } from 'yup';

export const step2Schema = object({
    birthDate: string()
        .required(' ')
        .matches(/^\d{2}\.\d{2}\.\d{4}$/, 'Неверный формат даты'),
    team: string()
        .required(' ')
        .matches(
            /^[а-яА-ЯёЁa-zA-Z\s0-9]+$/,
            'Только кириллические или латинские символы и цифры',
        ),
    nickname: string()
        .required(' ')
        .matches(
            /^[а-яА-ЯёЁa-zA-Z\s0-9]+$/,
            'Только кириллические или латинские символы и цифры',
        ),
    vkLink: string().matches(/^https:\/\/vk/, {
        message: 'Ссылка должна содержать "https://vk"',
        excludeEmptyString: true,
    }),
});
