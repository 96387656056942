import {
    ApiErrorInputsResponse,
    ApiErrorResponse,
} from '@/shared/model/types/error';

export function isApiResponse(error: unknown): error is ApiErrorResponse {
    return (
        typeof error === 'object' &&
        error != null &&
        'data' in error &&
        typeof error.data === 'object' &&
        'error' in error.data &&
        error.data.error === 'API exception'
    );
}

export const isApiInputsResponse = (
    error: unknown,
): error is ApiErrorInputsResponse => {
    return (
        typeof error === 'object' &&
        error != null &&
        'data' in error &&
        typeof error.data === 'object' &&
        'error' in error.data &&
        (error.data.error === 'Не корректные данные' ||
            error.data.error === 'Некорректные данные')
    );
};
