import s from './ChangePasswordForm.module.scss';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { Button } from '@/shared/ui/Button/Button';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '@/shared/lib/hooks/redux';
import { ChangePasswordFormData } from '../model/types/changePassword';
import React, { useEffect } from 'react';
import { Logo } from '@/shared/ui/Logo/Logo';
import { changePasswordSchema } from '../lib/validationSchema/changePasswordSchema';
import { InputPassword } from '@/shared/ui/InputPassword/InputPassword';
import { useChangePasswordMutation } from '@/features/ChangePasswordForm';
import { useAlert } from '@/shared/lib/hooks/useAlert';
import { useFormError } from '@/shared/lib/hooks/useFormError';
import { useScreenDetector } from '@/shared/lib/hooks/useScreenDetector';

export const ChangePasswordForm = () => {
    const navigate = useNavigate();
    const { isMobile } = useScreenDetector();
    const onAlert = useAlert();
    const { recoveryToken } = useAppSelector((state) => state.recoveryForm);
    const [triggerChangePassword, { isLoading, isSuccess, error, data }] =
        useChangePasswordMutation();
    const {
        control,
        handleSubmit,
        setError,
        formState: { errors, dirtyFields },
    } = useForm<ChangePasswordFormData>({
        resolver: yupResolver(changePasswordSchema),
        defaultValues: {
            newPassword: '',
            confirmPassword: '',
        },
    });

    const onSubmit: SubmitHandler<ChangePasswordFormData> = (data) => {
        triggerChangePassword({
            token: recoveryToken,
            newPassword: data.newPassword,
        });
    };

    useEffect(() => {
        if (isSuccess) {
            onAlert(data.message);
            navigate('/');
        }
    }, [isSuccess]);

    useFormError<ChangePasswordFormData>(error, setError);

    return (
        <form
            className={s.ChangePasswordForm}
            onSubmit={handleSubmit(onSubmit)}
        >
            <Logo size={isMobile ? 's' : 'l'} className={s.logo} />

            <h1 className={s.title}>Создание пароля</h1>
            <div className={s.inputs}>
                <Controller
                    name="newPassword"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                        <InputPassword
                            value={value}
                            onChange={onChange}
                            label={'Новый пароль'}
                            placeholder={'Введите пароль'}
                            error={errors.newPassword?.message}
                            isDirty={dirtyFields.newPassword}
                        />
                    )}
                />
                <Controller
                    name="confirmPassword"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                        <InputPassword
                            value={value}
                            onChange={onChange}
                            label={'Подтверждение пароля'}
                            placeholder={'Введите пароль'}
                            error={errors.confirmPassword?.message}
                            isDirty={dirtyFields.confirmPassword}
                        />
                    )}
                />
                <span className={s.info}>
                    Пароль должен содержать не менее 8 знаков, включая буквы,
                    цифры и символы
                </span>
            </div>

            <div className={s.actions}>
                <Button
                    type={'submit'}
                    disabled={isLoading}
                    size={isMobile ? 's' : 'm'}
                >
                    Создать
                </Button>
            </div>
        </form>
    );
};
