import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface AppState {
    phoneNumber: string;
    sendCodeAt: number;
    recoveryToken: string;
}

const initialState: AppState = {
    phoneNumber: '',
    sendCodeAt: null,
    recoveryToken: '',
};

export const recoveryFormSlice = createSlice({
    name: 'recoveryForm',
    initialState,
    reducers: {
        setPhoneNumber: (state, action: PayloadAction<string>) => {
            state.phoneNumber = action.payload;
        },
        setSendCodeAt: (state, action: PayloadAction<number>) => {
            state.sendCodeAt = action.payload;
        },
        setRecoveryToken: (state, action: PayloadAction<string>) => {
            state.recoveryToken = action.payload;
        },
    },
});

export const { setPhoneNumber, setSendCodeAt, setRecoveryToken } =
    recoveryFormSlice.actions;

export const { reducer: recoveryFormReducer } = recoveryFormSlice;
