import {
    InvisibleSmartCaptchaProps,
    SmartCaptcha,
} from '@yandex/smart-captcha';
import { memo } from 'react';

interface YandexCaptchaProps
    extends Omit<InvisibleSmartCaptchaProps, 'sitekey'> {
    resetCaptcha?: number;
}

export const YandexCaptcha = memo((props: YandexCaptchaProps) => {
    const { onSuccess, resetCaptcha } = props;

    return (
        <SmartCaptcha
            sitekey="ysc1_oddJrBhgCgKKkUhS8u0BORLFdeulhKiSCeTbtVD521bca2c4"
            onSuccess={onSuccess}
            key={resetCaptcha}
        />
    );
});
