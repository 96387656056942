import s from './Start.module.scss';
import { yupResolver } from '@hookform/resolvers/yup';
import { Input } from '@/shared/ui/Input/Input';
import { StartData } from '../../model/types/start';
import { Controller, useForm } from 'react-hook-form';
import { Button } from '@/shared/ui/Button/Button';
import { startSchema } from '../../lib/validationSchema/StartSchema';
import { useStartMutation } from '../../api/registrationApi';
import {
    setRegistrationData,
    setRegistrationToken,
    setSendCodeAt,
} from '@/features/RegistrationForm';
import { useAppDispatch, useAppSelector } from '@/shared/lib/hooks/redux';
import { useEffect, useState } from 'react';
import { Step1 } from '../Step1/Step1';
import { useRemainingTimer } from '@/shared/lib/hooks/useRemainingTimer';
import { InputTel } from '@/shared/ui/InputTel/InputTel';
import { useFormError } from '@/shared/lib/hooks/useFormError';
import { YandexCaptcha } from '@/shared/ui/YandexCaptcha/YandexCaptcha';
import { useScreenDetector } from '@/shared/lib/hooks/useScreenDetector';
import { nextStep } from '@/widgets/RegistrationStepper';
import { phoneNumberFormat } from '@/shared/lib/utils/phoneNumberFormat';

export const Start = () => {
    const dispatch = useAppDispatch();
    const [triggerStart, { isLoading, isSuccess, error, data }] =
        useStartMutation();
    const { isMobile } = useScreenDetector();

    const [captcha, setCaptcha] = useState('');
    const [visible, setVisible] = useState(false);
    const [resetCaptcha, setResetCaptcha] = useState(0);

    const {
        data: { phoneNumber, region, fullName },
        sendCodeAt,
        isCodeConfirmed,
    } = useAppSelector((state) => state.registration);

    const {
        control,
        handleSubmit,
        formState: { errors, dirtyFields, isDirty },
        setError,
        watch,
    } = useForm<StartData>({
        resolver: yupResolver(startSchema),
        defaultValues: {
            fullName: fullName || '',
            region: region || '',
            phoneNumber: phoneNumber || '',
        },
    });

    const timeRemaining = useRemainingTimer(30, sendCodeAt);

    const isDirtyAfterSend: boolean =
        fullName !== watch('fullName') ||
        region !== watch('region') ||
        phoneNumber !== watch('phoneNumber');

    const handleCaptchaReset = () => {
        setCaptcha('');
        setResetCaptcha((i) => i + 1);
    };

    const onSuccessCaptcha = (token: string) => {
        setCaptcha(token);
        handleSubmit((data) => onSubmit(data, token))();
    };

    const onSubmit = (data: StartData, token: string) => {
        if (visible && token) {
            triggerStart({
                phoneNumber: phoneNumberFormat(data.phoneNumber),
                captcha: token,
            }).then(() => {
                dispatch(
                    setRegistrationData({
                        phoneNumber: data.phoneNumber,
                        fullName: data.fullName,
                        region: data.region,
                    }),
                );
            });
        } else if (!visible) {
            setVisible(true);
        } else {
            handleCaptchaReset();
        }
    };

    useEffect(() => {
        if (isSuccess) {
            dispatch(setSendCodeAt(new Date().getTime()));
            dispatch(setRegistrationToken(data.registrationToken));
        }
    }, [isSuccess]);

    useFormError<{ phoneNumber: string }>(error, setError, handleCaptchaReset);

    return (
        <div className={s.wrapper}>
            <form
                className={s.inputs}
                onSubmit={handleSubmit((data) => onSubmit(data, captcha))}
            >
                <Controller
                    name="fullName"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                        <Input
                            value={value}
                            onChange={onChange}
                            label={'ФИО*'}
                            placeholder={'Введите ваше ФИО'}
                            error={errors.fullName?.message}
                            isDirty={dirtyFields.fullName}
                        />
                    )}
                />
                <Controller
                    name="region"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                        <Input
                            value={value}
                            onChange={onChange}
                            label={'Регион регистрации*'}
                            placeholder={'Введите регион'}
                            error={errors.region?.message}
                            isDirty={dirtyFields.region}
                        />
                    )}
                />
                <div className={s.actions}>
                    <Controller
                        name="phoneNumber"
                        control={control}
                        render={({ field: { value, onChange } }) => (
                            <InputTel
                                value={value}
                                onChange={onChange}
                                label={'Номер телефона'}
                                placeholder={'+7 900 000-00-00'}
                                error={errors.phoneNumber?.message}
                                isDirty={dirtyFields.phoneNumber}
                                baseAddon={false}
                            />
                        )}
                    />
                    <Button
                        type={'submit'}
                        textSize={'b4'}
                        width={'auto'}
                        className={s.button}
                        disabled={
                            isLoading ||
                            (!!timeRemaining && !isDirtyAfterSend) ||
                            (visible && !captcha)
                        }
                        size={isMobile ? 'xs' : 'm'}
                        height={isMobile && '47px'}
                    >
                        Отправить код
                    </Button>
                </div>
                {visible && (
                    <YandexCaptcha
                        onSuccess={onSuccessCaptcha}
                        resetCaptcha={resetCaptcha}
                    />
                )}
            </form>

            {isCodeConfirmed && !isDirty && !isLoading ? (
                <Button
                    type={'button'}
                    disabled={isLoading}
                    size={isMobile ? 's' : 'm'}
                    onClick={() => dispatch(nextStep())}
                >
                    Далее
                </Button>
            ) : (
                <Step1 disabled={isDirtyAfterSend || !!error || isLoading} />
            )}
        </div>
    );
};
