import classNames from 'classnames';
import s from './InputTel.module.scss';
import { ChangeEvent } from 'react';
import CheckIcon from '@/shared/assets/icons/check.svg';
import WarningIcon from '@/shared/assets/icons/warning.svg';
import { PatternFormat } from 'react-number-format';

interface InputTelProps {
    label: string;
    className?: string;
    placeholder?: string;
    error: string;
    value: string;
    onChange: (e: ChangeEvent<HTMLInputElement>) => void;
    disabled?: boolean;
    isDirty?: boolean;
    baseAddon?: boolean;
}

export const InputTel = (props: InputTelProps) => {
    const {
        className,
        label,
        placeholder,
        error,
        value = '',
        onChange,
        disabled,
        isDirty,
        baseAddon = true,
    } = props;

    const mods = {
        [s.disabled]: disabled,
        [s.isError]: !!error,
    };

    return (
        <div className={classNames(s.Input, mods, [className])}>
            <label className={s.label} htmlFor={label}>
                {label}
            </label>
            <div className={s.wrapper}>
                <PatternFormat
                    className={classNames(
                        s.input,
                        { [s.baseAddon]: baseAddon },
                        [className],
                    )}
                    value={value}
                    onChange={onChange}
                    placeholder={placeholder}
                    id={label}
                    disabled={disabled}
                    format="+7 ###-###-##-##"
                    mask="_"
                    type="tel"
                />

                {baseAddon && isDirty && (
                    <div className={s.status}>
                        {error ? (
                            <WarningIcon className={s.warning} />
                        ) : (
                            <CheckIcon className={s.check} />
                        )}
                    </div>
                )}
            </div>
            {error && error.trim().length > 0 && (
                <span className={s.error}>{error}</span>
            )}
        </div>
    );
};
