import s from './AuthContainer.module.scss';
import { Logo } from '@/shared/ui/Logo/Logo';
import { NavLink, Outlet } from 'react-router-dom';
import classNames from 'classnames';
import { useScreenDetector } from '@/shared/lib/hooks/useScreenDetector';

export const AuthContainer = () => {
    const { isMobile } = useScreenDetector();
    return (
        <div className={s.AuthContainer}>
            <Logo size={isMobile ? 's' : 'l'} className={s.logo} />

            <div className={s.tabs}>
                <NavLink
                    className={({ isActive }) =>
                        classNames(s.tab, { [s.active]: isActive }, [])
                    }
                    to={'login'}
                >
                    Вход
                </NavLink>
                <NavLink
                    className={({ isActive }) =>
                        classNames(s.tab, { [s.active]: isActive }, [])
                    }
                    to={`registration`}
                >
                    Регистрация
                </NavLink>
            </div>
            <Outlet />
        </div>
    );
};
