import { noAuthApi } from '@/shared/api/noAuthApi';

interface SendCode {
    token: string;
}

export interface SendCodeQuery {
    token: string;
    code: string;
}

const recoveryApi = noAuthApi.injectEndpoints({
    endpoints: (build) => ({
        sendCode: build.mutation<SendCode, SendCodeQuery>({
            query: (data) => ({
                url: '/reset-password/submit-code',
                method: 'POST',
                body: data,
            }),
        }),
    }),
});

export const { useSendCodeMutation } = recoveryApi;
