import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ReactNode } from 'react';

interface AlertState {
    isOpen: boolean;
    isError: boolean;
    content: ReactNode;
}

const initialState: AlertState = {
    isOpen: false,
    isError: false,
    content: null,
};

export const alertSlice = createSlice({
    name: 'alert',
    initialState,
    reducers: {
        alertOpen: (
            state,
            action: PayloadAction<{ content: ReactNode; isError: boolean }>,
        ) => {
            state.isOpen = true;
            state.content = action.payload.content;
            state.isError = action.payload.isError;
        },
        alertClose: (state) => {
            state.isOpen = false;
            state.isError = false;
            state.content = null;
        },
    },
});

export const { alertOpen, alertClose } = alertSlice.actions;

export const { reducer: alertReducer } = alertSlice;
