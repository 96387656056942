import { useEffect } from 'react';
import { isApiResponse } from '@/shared/lib/utils/typeChecker';
import { useAlert } from '@/shared/lib/hooks/useAlert';

export const useError = (error: any, resetCaptcha?: () => void) => {
    const onAlert = useAlert();
    useEffect(() => {
        if (error)
            if (isApiResponse(error)) {
                if (error.data.details.error === 'Captcha is not valid') {
                    resetCaptcha && resetCaptcha();
                } else {
                    onAlert(error.data.details.message, true);
                }
            } else onAlert('Произошла неизвестная ошибка', true);
    }, [error]);
};
