import s from './Success.module.scss';
import { Logo } from '@/shared/ui/Logo/Logo';
import CheckCircle from '@/shared/assets/icons/check_circle.svg';
import { Button } from '@/shared/ui/Button/Button';
import { useNavigate } from 'react-router-dom';

export const Success = () => {
    const navigate = useNavigate();

    return (
        <div className={s.Success}>
            <Logo />
            <div className={s.wrapper}>
                <div>
                    <h1 className={s.title}>Вы зарегистрированы</h1>
                    <span className={s.info}>
                        Учётная запись успешно создана
                    </span>
                </div>
                <CheckCircle className={s.icon} />
                <Button
                    maxWidth={'250px'}
                    onClick={() => navigate('/auth/login')}
                >
                    Войти
                </Button>
            </div>
        </div>
    );
};
