import { object, string } from 'yup';

export const step3Schema = object({
    login: string()
        .required(' ')
        .min(5, 'Минимальная длинна логина 5')
        .max(30, 'Максимальная длинна логина 30')
        .matches(/^[a-zA-Z]+$/, 'Только латинские символы'),
    password: string()
        .required(' ')
        .max(64, 'Максимальная длинна пароля 64')
        .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[~!?\@\#\$\%\^&\*\_\-\+\(\)\[\]\{\}<>\/\\|\\"\'\.,:])[A-Za-z\d~!?\@\#\$\%\^&\*\_\-\+\(\)\[\]\{\}<>\/\\|\\"\'\.,:]{8,64}$/,
            {
                message: 'Пароль не соответсвует требованиям',
            },
        ),
    confirmPassword: string()
        .required(' ')
        .test(function (value) {
            return this.parent.password !== value
                ? this.createError({
                      message: `Пароли не совпадают`,
                      path: 'confirmPassword',
                  })
                : true;
        }),
});
