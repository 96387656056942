import { object, string } from 'yup';

export const loginSchema = object({
    login: string()
        .required(' ')
        .matches(
            /^(\+7\s?)?((\(\d{3}\)?)|\d{3})([\s.-]?\d{3})([\s.-]?\d{2})([\s.-]?\d{2})$/,
            'Неверный номер телефона',
        ),
    password: string().required(' '),
});
