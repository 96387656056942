import s from './RecoveryCodeForm.module.scss';
import { yupResolver } from '@hookform/resolvers/yup';
import { Input } from '@/shared/ui/Input/Input';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { Button } from '@/shared/ui/Button/Button';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '@/shared/lib/hooks/redux';
import { RecoveryCodeFormData } from '../model/types/recoveryCode';
import React, { useEffect, useState } from 'react';
import { Logo } from '@/shared/ui/Logo/Logo';
import { recoveryCodeSchema } from '../lib/validationSchema/recoveryCodeSchema';
import { useRemainingTimer } from '@/shared/lib/hooks/useRemainingTimer';
import {
    setRecoveryToken,
    setSendCodeAt,
    useResetPasswordMutation,
} from '@/features/RecoveryForm';
import { useError } from '@/shared/lib/hooks/useError';
import { useSendCodeMutation } from '@/features/RecoveryCodeForm';
import { useFormError } from '@/shared/lib/hooks/useFormError';
import { YandexCaptcha } from '@/shared/ui/YandexCaptcha/YandexCaptcha';
import { useScreenDetector } from '@/shared/lib/hooks/useScreenDetector';

export const RecoveryCodeForm = () => {
    const dispatch = useAppDispatch();
    const { isMobile } = useScreenDetector();
    const navigate = useNavigate();
    const [captcha, setCaptcha] = useState('');
    const [resetCaptcha, setResetCaptcha] = useState(0);
    const [visible, setVisible] = useState(false);

    const { sendCodeAt, phoneNumber, recoveryToken } = useAppSelector(
        (state) => state.recoveryForm,
    );

    const [
        triggerResetPassword,
        {
            isLoading: isLoadingReset,
            isSuccess: isSuccessReset,
            error: errorReset,
            data: dataReset,
        },
    ] = useResetPasswordMutation();
    const [
        triggerSendCode,
        {
            isLoading: isLoadingSend,
            isSuccess: isSuccessSend,
            error: errorSend,
            data: dataSend,
        },
    ] = useSendCodeMutation();

    const {
        control,
        handleSubmit,
        watch,
        formState: { errors, dirtyFields },
        setError,
    } = useForm<RecoveryCodeFormData>({
        resolver: yupResolver(recoveryCodeSchema),
        defaultValues: {
            code: '',
        },
    });

    const timeRemaining = useRemainingTimer(30, sendCodeAt);

    const handleCaptchaReset = () => {
        setCaptcha('');
        setResetCaptcha((i) => i + 1);
    };

    const onSuccessCaptcha = (token: string) => {
        setCaptcha(token);
        sendCode(token);
    };

    const onSubmit: SubmitHandler<RecoveryCodeFormData> = (data) => {
        triggerSendCode({
            code: data.code,
            token: recoveryToken,
        });
    };

    const sendCode = (token: string) => {
        if (visible && token) {
            triggerResetPassword({
                phoneNumber: phoneNumber,
                captcha: token,
            });
        } else if (!visible) {
            setVisible(true);
        } else {
            handleCaptchaReset();
        }
    };

    const codeValue = watch('code');

    useEffect(() => {
        if (codeValue.length >= 4) {
            handleSubmit(onSubmit)();
        }
    }, [codeValue]);

    useEffect(() => {
        if (isSuccessSend) {
            dispatch(setRecoveryToken(dataSend.token));
            navigate('/recovery/change-password');
        }
    }, [isSuccessSend]);

    useEffect(() => {
        if (isSuccessReset) {
            dispatch(setSendCodeAt(new Date().getTime()));
            dispatch(setRecoveryToken(dataReset.token));
        }
    }, [isSuccessReset]);

    useError(errorReset, handleCaptchaReset);
    useFormError<RecoveryCodeFormData>(errorSend, setError);

    return (
        <form className={s.RecoveryCodeForm} onSubmit={handleSubmit(onSubmit)}>
            <Logo size={isMobile ? 's' : 'l'} className={s.logo} />

            <h1 className={s.title}>Код подтверждения</h1>
            <div className={s.inputs}>
                <Controller
                    name="code"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                        <Input
                            value={value}
                            onChange={onChange}
                            label={'Введите код'}
                            placeholder={'00–00'}
                            error={errors.code?.message}
                            isDirty={dirtyFields.code}
                            baseAddon={false}
                            disabled={isLoadingReset}
                        />
                    )}
                />
                {timeRemaining > 0 && (
                    <span className={s.timer}>
                        Код подтверждения можно запросить повторно через:{' '}
                        {timeRemaining} секунд
                    </span>
                )}
            </div>

            <div className={s.actions}>
                <Button
                    onClick={() => sendCode(captcha)}
                    disabled={timeRemaining > 0 || isLoadingSend}
                    size={isMobile ? 's' : 'm'}
                >
                    Отправить ещё раз
                </Button>
                {visible && (
                    <YandexCaptcha
                        onSuccess={onSuccessCaptcha}
                        resetCaptcha={resetCaptcha}
                    />
                )}
                <Button
                    onClick={() => navigate('/recovery')}
                    variant={'outline'}
                    size={isMobile ? 's' : 'm'}
                >
                    Изменить номер
                </Button>
            </div>
        </form>
    );
};
