import s from './Alert.module.scss';
import Close from '@/shared/assets/icons/close.svg';
import { IconButton } from '@/shared/ui/IconButton/IconButton';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '@/shared/lib/hooks/redux';
import { Portal } from '@/shared/ui/Portal/Portal';
import { useEffect } from 'react';
import { alertClose } from '@/entities/Alert';
import classNames from 'classnames';

export const Alert = () => {
    const dispatch = useDispatch();
    const { content, isError } = useAppSelector((state) => state.alert);
    const onClose = () => {
        dispatch(alertClose());
    };

    useEffect(() => {
        const timeout = setTimeout(() => dispatch(alertClose()), 5000);

        return () => clearTimeout(timeout);
    }, [content]);

    return (
        <Portal>
            <div className={s.Alert}>
                <div
                    className={classNames(s.content, { [s.isError]: isError })}
                >
                    {content}
                </div>
                <IconButton size={'xs'} onClick={onClose}>
                    <Close />
                </IconButton>
            </div>
        </Portal>
    );
};
