import s from './NotFound.module.scss';
import { Logo } from '@/shared/ui/Logo/Logo';

export const NotFound = () => {
    return (
        <div className={s.NotFound}>
            <div className={s.popup}>
                <Logo className={s.logo} size={'l'} />
                <span className={s.title}>404</span>
                <span className={s.info}>
                    Что‑то пошло не так. Обновите страницу или повторите попытку
                    позднее.
                </span>
            </div>
        </div>
    );
};
