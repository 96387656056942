import { noAuthApi } from '@/shared/api/noAuthApi';

interface Login {
    status: string;
    token: string;
}

export interface LoginQuery {
    login: string;
    password: string;
}

const loginApi = noAuthApi.injectEndpoints({
    endpoints: (build) => ({
        login: build.mutation<Login, LoginQuery>({
            query: (data) => ({
                url: '/auth',
                method: 'POST',
                body: data,
            }),
        }),
    }),
});

export const { useLoginMutation } = loginApi;
