import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface AppState {
    data: {
        fullName: string;
        region: string;
        phoneNumber: string;
        birthDate: string;
        team: string;
        nickname: string;
        vkLink: string;
    };
    registrationToken: string;
    sendCodeAt: number;
    isCodeConfirmed: boolean;
}

const initialState: AppState = {
    data: {
        phoneNumber: '',
        region: '',
        fullName: '',
        birthDate: '',
        team: '',
        nickname: '',
        vkLink: '',
    },
    registrationToken: null,
    sendCodeAt: null,
    isCodeConfirmed: false,
};

export const registrationSlice = createSlice({
    name: 'registration',
    initialState,
    reducers: {
        setRegistrationData: (
            state,
            action: PayloadAction<Partial<AppState>>,
        ) => {
            state.data = {
                ...state.data,
                ...action.payload,
            };
        },
        resetRegistrationData: () => initialState,
        setRegistrationToken: (state, action: PayloadAction<string>) => {
            state.registrationToken = action.payload;
        },
        setIsCodeConfirmed: (state, action: PayloadAction<boolean>) => {
            state.isCodeConfirmed = action.payload;
        },
        setSendCodeAt: (state, action: PayloadAction<number>) => {
            state.sendCodeAt = action.payload;
        },
    },
});

export const {
    setRegistrationData,
    setRegistrationToken,
    setSendCodeAt,
    resetRegistrationData,
    setIsCodeConfirmed,
} = registrationSlice.actions;

export const { reducer: registrationReducer } = registrationSlice;
