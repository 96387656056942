import { noAuthApi } from '@/shared/api/noAuthApi';

export interface StartQuery {
    phoneNumber: string;
    captcha: string;
}
export interface Step1Query {
    registrationToken: string;
    fullName: string;
    region: string;
    code: string;
}
export interface Step2Query {
    registrationToken: string;
    birthDate: string;
    team: string;
    nickname: string;
    vkLink: string;
}
export interface Step3Query {
    registrationToken: string;
    login: string;
    password: string;
}

export interface TokenResponse {
    registrationToken: string;
}

const registrationApi = noAuthApi.injectEndpoints({
    endpoints: (build) => ({
        start: build.mutation<TokenResponse, StartQuery>({
            query: (data) => ({
                url: '/registration/start',
                method: 'POST',
                body: data,
            }),
        }),
        step1: build.mutation<TokenResponse, Step1Query>({
            query: (data) => ({
                url: '/registration/step-1',
                method: 'POST',
                body: data,
            }),
        }),
        step2: build.mutation<TokenResponse, Step2Query>({
            query: (data) => ({
                url: '/registration/step-2',
                method: 'POST',
                body: data,
            }),
        }),
        step3: build.mutation<void, Step3Query>({
            query: (data) => ({
                url: '/registration/step-3',
                method: 'POST',
                body: data,
            }),
        }),
    }),
});

export const {
    useStartMutation,
    useStep1Mutation,
    useStep2Mutation,
    useStep3Mutation,
} = registrationApi;
