import s from './Checkbox.module.scss';
import { useId } from 'react';
import classNames from 'classnames';

interface CheckboxProps {
    className?: string;
    label?: string;
    error?: string;
    checked?: boolean;
    onChange?: () => void;
}

export const Checkbox = (props: CheckboxProps) => {
    const { className, checked, onChange, label } = props;
    const id = useId();

    const mods = {
        [s.checked]: checked,
    };

    return (
        <div className={classNames(s.Input, mods, [className])}>
            <label htmlFor={id} className={s.wrapper}>
                <div className={s.checkbox} />
                {label}
            </label>
            <input
                className={s.input}
                type="checkbox"
                id={id}
                checked={checked}
                onChange={onChange}
            />
        </div>
    );
};
