import { configureStore } from '@reduxjs/toolkit';
import { rtkApi } from '@/shared/api/rtkApi';
import { noAuthApi } from '@/shared/api/noAuthApi';
import { registrationStepperReducer } from '@/widgets/RegistrationStepper';
import { registrationReducer } from '@/features/RegistrationForm';
import { recoveryFormReducer } from '@/features/RecoveryForm/model/slice/recoverySlice';
import { appReducer } from '@/app/model/slice/appSlice';
import { alertReducer } from '@/entities/Alert';

export const store = configureStore({
    devTools: __MODE__ === 'development',
    reducer: {
        app: appReducer,
        registration: registrationReducer,
        registrationStepper: registrationStepperReducer,
        recoveryForm: recoveryFormReducer,
        alert: alertReducer,
        [rtkApi.reducerPath]: rtkApi.reducer,
        [noAuthApi.reducerPath]: noAuthApi.reducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware()
            .concat(rtkApi.middleware)
            .concat(noAuthApi.middleware),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
