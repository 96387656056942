import { rtkApi } from '@/shared/api/rtkApi';

interface Profile {
    fullName: string;
    name: string;
    region: string;
    nickname: string;
    qualification: string;
    vkLink: string;
    phoneNumber: string;
    team: string;
    rate: string;
    status: string;
    birthDate: string;
    creatorRoom: boolean;
}

const profileApi = rtkApi.injectEndpoints({
    endpoints: (build) => ({
        getProfileData: build.query<Profile, void>({
            query: () => `/v1/profile/info`,
            providesTags: ['Profile'],
        }),
    }),
});

export const { useGetProfileDataQuery } = profileApi;
