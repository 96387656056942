import { noAuthApi } from '@/shared/api/noAuthApi';
import { RecoveryFormData } from '@/features/RecoveryForm/model/types/recovery';

interface ResetPassword {
    token: string;
}
interface ResetPasswordQuery extends RecoveryFormData {
    captcha: string;
}

const recoveryApi = noAuthApi.injectEndpoints({
    endpoints: (build) => ({
        resetPassword: build.mutation<ResetPassword, ResetPasswordQuery>({
            query: (data) => ({
                url: '/reset-password/request',
                method: 'POST',
                body: data,
            }),
        }),
    }),
});

export const { useResetPasswordMutation } = recoveryApi;
