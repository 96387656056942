import { ReactNode } from 'react';
import { useDispatch } from 'react-redux';
import { alertOpen } from '@/entities/Alert';

export const useAlert = () => {
    const dispatch = useDispatch();
    const onAlert = (content: ReactNode, isError?: boolean) => {
        dispatch(alertOpen({ content, isError }));
    };

    return onAlert;
};
