import { noAuthApi } from '@/shared/api/noAuthApi';

interface ChangePassword {
    message: string;
}

export interface ChangePasswordQuery {
    token: string;
    newPassword: string;
}

const changePasswordApi = noAuthApi.injectEndpoints({
    endpoints: (build) => ({
        changePassword: build.mutation<ChangePassword, ChangePasswordQuery>({
            query: (data) => ({
                url: '/reset-password/submit-new-password',
                method: 'POST',
                body: data,
            }),
        }),
    }),
});

export const { useChangePasswordMutation } = changePasswordApi;
