import s from './RegistrationStepper.module.scss';
import { useAppSelector } from '@/shared/lib/hooks/redux';
import classNames from 'classnames';
import { Outlet, useNavigate } from 'react-router-dom';
import React, { useEffect } from 'react';
import { PageTitle } from '@/shared/ui/PageTitle/PageTitle';

export const RegistrationStepper = () => {
    const { currentStep, totalStep } = useAppSelector(
        (state) => state.registrationStepper,
    );

    const navigate = useNavigate();
    const arrayTotalStep = Array.from({ length: totalStep }, (_, i) => i + 1);

    useEffect(() => {
        navigate(String(currentStep) ?? '1');
    }, [currentStep]);

    return (
        <div className={s.RegistrationStepper}>
            <PageTitle title="Регистрация | VRStrike" />
            <div className={s.progress}>
                <h2 className={s.stepCount}>
                    Шаг {currentStep}/{totalStep}
                </h2>
                <div className={s.progressBar}>
                    {arrayTotalStep.map((item) => (
                        <span
                            key={item}
                            className={classNames(s.tab, {
                                [s.active]: item <= currentStep,
                            })}
                        />
                    ))}
                </div>
            </div>
            <Outlet />
        </div>
    );
};
