import s from './LoginForm.module.scss';
import { yupResolver } from '@hookform/resolvers/yup';
import { LoginFormData } from '../model/types/login';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { loginSchema } from '@/features/LoginForm/lib/validationSchema/loginSchema';
import { Button } from '@/shared/ui/Button/Button';
import { Link, useNavigate } from 'react-router-dom';
import { InputPassword } from '@/shared/ui/InputPassword/InputPassword';
import { useAppDispatch } from '@/shared/lib/hooks/redux';
import {
    LoginQuery,
    useLoginMutation,
} from '@/features/LoginForm/api/loginApi';
import { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { setIsAuth } from '@/app/model/slice/appSlice';
import { useFormError } from '@/shared/lib/hooks/useFormError';
import { Checkbox } from '@/shared/ui/Checkbox/Checkbox';
import { InputTel } from '@/shared/ui/InputTel/InputTel';
import { useScreenDetector } from '@/shared/lib/hooks/useScreenDetector';
import { PageTitle } from '@/shared/ui/PageTitle/PageTitle';
import { phoneNumberFormat } from '@/shared/lib/utils/phoneNumberFormat';

export const LoginForm = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { isMobile } = useScreenDetector();
    const [isRemember, setIsRemember] = useState(false);
    const [, setCookie] = useCookies();
    const [triggerLogin, { isLoading, isSuccess, error, data }] =
        useLoginMutation();

    const {
        control,
        handleSubmit,
        formState: { errors, dirtyFields },
        setError,
    } = useForm<LoginFormData>({
        resolver: yupResolver(loginSchema),
        defaultValues: {
            login: '',
            password: '',
        },
    });

    const onSubmit: SubmitHandler<LoginFormData> = (data) => {
        triggerLogin({
            login: phoneNumberFormat(data.login),
            password: data.password,
        });
    };

    useEffect(() => {
        if (isSuccess) {
            isRemember
                ? setCookie('token', data.token, { path: '/' })
                : sessionStorage.setItem('token', data.token);
            dispatch(setIsAuth(true));
            navigate('/');
        }
    }, [isSuccess, isRemember]);

    useFormError<LoginQuery>(error, setError);

    return (
        <form className={s.LoginForm} onSubmit={handleSubmit(onSubmit)}>
            <PageTitle title="Авторизация | VRStrike" />
            <div className={s.inputs}>
                <Controller
                    name="login"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                        <InputTel
                            value={value}
                            onChange={onChange}
                            label={'Номер телефона'}
                            placeholder={'Введите данные'}
                            error={errors.login?.message}
                            isDirty={dirtyFields.login}
                        />
                    )}
                />
                <Controller
                    name="password"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                        <InputPassword
                            value={value}
                            onChange={onChange}
                            label={'Пароль'}
                            placeholder={'Введите пароль'}
                            error={errors.password?.message}
                            isDirty={dirtyFields.password}
                        />
                    )}
                />
                <Checkbox
                    checked={isRemember}
                    onChange={() => setIsRemember((prevState) => !prevState)}
                    label={'Запомнить меня'}
                />
            </div>

            <div className={s.actions}>
                <Button
                    size={isMobile ? 's' : 'm'}
                    type={'submit'}
                    className={s.button}
                    disabled={isLoading}
                >
                    Войти
                </Button>
                <Link to={'/recovery'} className={s.recovery}>
                    Забыли пароль?
                </Link>
            </div>
        </form>
    );
};
