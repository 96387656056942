import { RouteObject } from 'react-router-dom';
import { Profile } from '@/pages/Profile';
import { NotFound } from '@/pages/NotFound';

export const routeConfig: RouteObject[] = [
    {
        path: '/',
        element: <Profile />,
        errorElement: <NotFound />,
    },
];
