import { object, string } from 'yup';

export const changePasswordSchema = object({
    newPassword: string()
        .required(' ')
        .max(64, 'Максимальная длинна пароля 64')
        .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[~!?\@\#\$\%\^&\*\_\-\+\(\)\[\]\{\}<>\/\\|\\"\'\.,:])[A-Za-z\d~!?\@\#\$\%\^&\*\_\-\+\(\)\[\]\{\}<>\/\\|\\"\'\.,:]{8,64}$/,
            {
                message: 'Пароль не соответсвует требованиям',
            },
        ),
    confirmPassword: string()
        .required(' ')
        .test(function (value) {
            return this.parent.newPassword !== value
                ? this.createError({
                      message: `Пароли не совпадают`,
                      path: 'confirmPassword',
                  })
                : true;
        }),
});
