import { useEffect } from 'react';
import {
    isApiInputsResponse,
    isApiResponse,
} from '@/shared/lib/utils/typeChecker';
import { useAlert } from '@/shared/lib/hooks/useAlert';

// В качестве <T> передавайть интерфейс соответсвующий полям формы
// Иначе есть риск, что пользователь не будет проинформирован об ошибке
export const useFormError = <T>(
    error: any,
    setError: any,
    resetCaptcha?: () => void,
) => {
    const onAlert = useAlert();
    useEffect(() => {
        if (error)
            if (isApiResponse(error)) {
                if (error.data.details.error === 'Captcha is not valid') {
                    resetCaptcha && resetCaptcha();
                } else {
                    onAlert(error.data.details.message, true);
                }
            } else if (isApiInputsResponse(error)) {
                const object = error.data.details;

                Object.keys(object).forEach((key) => {
                    // Использовать только с названиями полей с бэка
                    if (key === 'captcha') {
                        onAlert('Произошла ошибка при проверке капчи', true);
                    } else
                        setError(key as keyof T, {
                            message: object[key][0],
                        });
                });
            } else onAlert('Произошла неизвестная ошибка', true);
    }, [error, setError]);
};
