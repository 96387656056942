import { Navigate, RouteObject } from 'react-router-dom';
import { AuthLayout } from '@/pages/AuthLayout';
import { AuthContainer } from '@/widgets/AuthContainer';
import { LoginForm } from '@/features/LoginForm';
import { RegistrationStepper } from '@/widgets/RegistrationStepper';
import { Start, Step2, Step3 } from '@/features/RegistrationForm';
import { Success } from '@/features/RegistrationForm/ui/Success/Success';
import { NotFound } from '@/pages/NotFound';
import { RecoveryForm } from '@/features/RecoveryForm';
import { RecoveryCodeForm } from '@/features/RecoveryCodeForm';
import { ChangePasswordForm } from '@/features/ChangePasswordForm';

export const noAuthConfig: RouteObject[] = [
    {
        element: <AuthLayout />,
        errorElement: <NotFound />,
        children: [
            {
                path: '/auth',
                element: <AuthContainer />,
                children: [
                    {
                        path: 'login',
                        element: <LoginForm />,
                    },
                    {
                        path: 'registration',
                        element: <RegistrationStepper />,
                        children: [
                            {
                                path: '1',
                                element: <Start />,
                            },
                            {
                                path: '2',
                                element: <Step2 />,
                            },
                            {
                                path: '3',
                                element: <Step3 />,
                            },
                        ],
                    },
                ],
            },
            {
                path: '/success',
                element: <Success />,
            },
            {
                path: '/recovery',
                element: <RecoveryForm />,
            },
            {
                path: '/recovery/code',
                element: <RecoveryCodeForm />,
            },
            {
                path: '/recovery/change-password',
                element: <ChangePasswordForm />,
            },

            {
                path: '*',
                element: <Navigate to="/auth/login" replace />,
            },
        ],
    },
];
