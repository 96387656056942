import s from './RecoveryForm.module.scss';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import { Button } from '@/shared/ui/Button/Button';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '@/shared/lib/hooks/redux';
import { RecoveryFormData } from '../model/types/recovery';
import React, { useEffect, useState } from 'react';
import { Logo } from '@/shared/ui/Logo/Logo';
import { recoverySchema } from '../lib/validationSchema/recoverySchema';
import {
    setPhoneNumber,
    setRecoveryToken,
    setSendCodeAt,
    useResetPasswordMutation,
} from '@/features/RecoveryForm';
import { InputTel } from '@/shared/ui/InputTel/InputTel';
import { useFormError } from '@/shared/lib/hooks/useFormError';
import { YandexCaptcha } from '@/shared/ui/YandexCaptcha/YandexCaptcha';
import { PageTitle } from '@/shared/ui/PageTitle/PageTitle';
import { useScreenDetector } from '@/shared/lib/hooks/useScreenDetector';

export const RecoveryForm = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { isMobile } = useScreenDetector();
    const { phoneNumber, recoveryToken } = useAppSelector(
        (state) => state.recoveryForm,
    );

    const [captcha, setCaptcha] = useState('');
    const [resetCaptcha, setResetCaptcha] = useState(0);
    const [visible, setVisible] = useState(false);

    const [triggerResetPassword, { isLoading, isSuccess, error, data }] =
        useResetPasswordMutation();

    const {
        control,
        handleSubmit,
        formState: { errors, dirtyFields },
        setError,
        clearErrors,
        watch,
    } = useForm<RecoveryFormData>({
        resolver: yupResolver(recoverySchema),
        defaultValues: {
            phoneNumber: phoneNumber || '',
        },
    });

    const isDirtyAfterSend: boolean = phoneNumber !== watch('phoneNumber');

    const handleCaptchaReset = () => {
        setCaptcha('');
        setResetCaptcha((i) => i + 1);
    };

    const onSuccessCaptcha = (token: string) => {
        setCaptcha(token);
        handleSubmit((data) => onSubmit(data, token))();
    };
    const onSubmit = (data: RecoveryFormData, token: string) => {
        if (visible && token) {
            triggerResetPassword({
                phoneNumber: data.phoneNumber,
                captcha: token,
            });
            dispatch(setPhoneNumber(data.phoneNumber));
        } else if (!visible) {
            setVisible(true);
        } else {
            handleCaptchaReset();
        }
    };

    useEffect(() => {
        if (isSuccess) {
            dispatch(setSendCodeAt(new Date().getTime()));
            dispatch(setRecoveryToken(data.token));
            navigate('code');
        }
    }, [isSuccess]);

    useFormError<RecoveryFormData>(error, setError, handleCaptchaReset);

    return (
        <form
            className={s.RecoveryForm}
            onSubmit={handleSubmit((data) => onSubmit(data, captcha))}
        >
            <PageTitle title="Восстановление | VRStrike" />
            <Logo size={isMobile ? 's' : 'l'} className={s.logo} />

            <h1 className={s.title}>Восстановление пароля</h1>
            <div className={s.inputs}>
                <Controller
                    name="phoneNumber"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                        <InputTel
                            value={value}
                            onChange={(e) => {
                                clearErrors('root');
                                onChange(e);
                            }}
                            label={'Номер телефона'}
                            placeholder={'+7 900 000-00-00'}
                            error={errors.phoneNumber?.message}
                            isDirty={dirtyFields.phoneNumber}
                        />
                    )}
                />
                {visible && (
                    <YandexCaptcha
                        onSuccess={onSuccessCaptcha}
                        resetCaptcha={resetCaptcha}
                    />
                )}

                <span className={s.info}>
                    На указанный номер телефона поступит СМС с кодом
                    подтверждения
                </span>
            </div>

            <div className={s.actions}>
                {isDirtyAfterSend || !recoveryToken || !!error || isLoading ? (
                    <Button
                        type={'submit'}
                        disabled={isLoading}
                        size={isMobile ? 's' : 'm'}
                    >
                        Отправить
                    </Button>
                ) : (
                    <Button
                        type={'button'}
                        onClick={() => navigate('code')}
                        size={isMobile ? 's' : 'm'}
                    >
                        Далее
                    </Button>
                )}
                <Button
                    onClick={() => navigate('/auth/login')}
                    variant={'outline'}
                    size={isMobile ? 's' : 'm'}
                >
                    Назад
                </Button>
            </div>
        </form>
    );
};
